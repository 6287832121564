<template>
	<div class="page-status-submitted">
		<div class="custom-headline">入驻申请</div>
		<el-card>
			<Step v-model="stepActiveIndex"></Step>
			<div class="inner-box">
				<img :src="assets.img.success" class="icon">
				<div>您的入驻资料已提交成功，请等待审核，您可以点击<div class="link">此处<div class="mask" @click="jump2queryProgressing"></div></div>查看进度
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
import Step from "../components/Step";
export default {
	components: {
		Step,
	},
	data() {
		return {
			stepActiveIndex: [1, 3],
			assets: {
				img: {
					success: require('../assets/img/progress_submit.png')
				}
			}
		};
	},
	methods: {
    jump2queryProgressing() {
      this.$router.push({
        name: 'StatusQueryProgress'
      })
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/base.scss";
.page-status-submitted {
	.inner-box {
		font-size: 18px;
		color: #666666;
    padding: 90px 0;
    text-align: center;
		.icon {
			width: 180px;
			height: 180px;
      margin:0 auto 23px;
		}
    .link {
      position: relative;
      display: inline-block;
      color: $color-theme;
      font-weight: 500;
      .mask {
        position: absolute;
        top: -10px;
        right: -10px;
        bottom: -10px;
        left: -10px;
        cursor: pointer;
        z-index: 9;
      }
    }
	}
}
</style>